import React from 'react';
import './whatsNew.css';
import FooterContainer from '../footerContainer/FooterContainer';

class WhatsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="container" >
        <div id="WhatsNewModalMainContainer">
 
        </div>
        <div id="AppFooterContainer">
          <FooterContainer />
        </div>
      </div>

    )
  }

}

export default WhatsNew;
