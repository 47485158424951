import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
class CompanyOverviewTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "NavLinkOurCompany"
        }
    }

    toggle = tab => event => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab })
    }

    render() {
        return (
            <div id="companyOverviewTabContainer">
                <Nav tabs>
                    <NavItem  style={window.innerWidth > 767 ?{}:{width:"100%", marginBottom:"5px", borderRadius:"20px"}}>
                        <NavLink id="NavLinkOurCompany"
                            onClick={this.toggle('NavLinkOurCompany')}
                            className={this.state.activeTab === "NavLinkOurCompany" ? "boldHeader companyOverviewTabContainerNavLink" : "companyOverviewTabContainerNavLink"}

                        > Our Company
                        </NavLink>
                    </NavItem>
                    <NavItem   style={window.innerWidth > 767 ?{}:{width:"100%", marginBottom:"5px", borderRadius:"20px"}}>
                        <NavLink id="NavLinkOurServices"
                            onClick={this.toggle('NavLinkOurServices')}
                            className={this.state.activeTab === "NavLinkOurServices" ? "boldHeader companyOverviewTabContainerNavLink" : "companyOverviewTabContainerNavLink"}
                        >Our Services
                        </NavLink>
                    </NavItem>
                    <NavItem   style={window.innerWidth > 767 ?{}:{width:"100%", marginBottom:"5px", borderRadius:"20px"}}>
                        <NavLink id="NavLinkOurTeam"
                            onClick={this.toggle('NavLinkOurTeam')}
                            className={this.state.activeTab === "NavLinkOurTeam" ? "boldHeader companyOverviewTabContainerNavLink" : "companyOverviewTabContainerNavLink"}
                        >Our Team
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="NavLinkOurCompany">
                        <p>For more than <strong>35 years</strong> , C.T.Serv has been a leading Solution Provider and System Integrator.
                            We provide Software Applicationsand Computer Hardware that cover major Business sectors.
                            Since the beginning, we’ve focused on providing reliable solutions that will ultimately serve our clients.
                            Our clients are covered with ultimate care plans and professional support, corrective and preventive,
                            using the high speed internet and wireless connections. We create value for you by helping you create value for your customers!</p>

                        <p>It started off with a simple idea: It’s not just about the Solution;
                            it’s about the support and long-lasting client relations! This is why we developed a complete
                            Distribution and Service Infrastructure for the fastest available service in the market. At C.T.Serv, our dedication
                            for our customers drives who we are and what we do.
                            During our years of professional performance, we managed to win the confidence of our client base which includes:</p>

                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Hospitals, Medical Centers</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Supermarkets, Minimarkets</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Chain Stores, Bookstores, Fashion Shops …</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Insurance Companies</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Universities International & Local Corporate Clients</strong></p>
                    </TabPane>
                    <TabPane tabId="NavLinkOurServices">
                        <p>Being both a Software Applications and Computer Hardware provider,
                            we are proud to be working with the latest technologies in order to create leading-edge,
                             secure and integrated solutions that will make our clients enjoy the digital world!</p>

                        <p>We have been proud to provide a wide range of services to our client base:</p>

                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i> Software Applications, Servers and Networks</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></strong> On-Site & Remote <strong>Customer Support</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Products Specialist</strong></p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>Installation</strong> and fine Tuning</p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></strong> <strong>After Sales</strong> services and annual maintenance contracts</p>
                        <p><strong><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></strong> Complete <strong>Communication Setup</strong></p>
                    </TabPane>
                    <TabPane tabId="NavLinkOurTeam">
                        <p>We are proud to have built a group of 93 talented, loyal and committed individuals who believe in the value they bring to our clients.</p>

                        <p>We prosper on the success of our people and foster a work environment of growth and opportunities.</p>

                        <p>Each member of C.T.Serv belongs to a family of trust, integrity and collaboration.</p>

                        <p><span style={{ fontStyle: "italic" }}><strong>‘None of us is as smart as all of us’.</strong></span>  <strong>~Ken Blanchard</strong></p>
                    </TabPane>

                </TabContent>
            </div>
        );
    }
}
export default CompanyOverviewTab;