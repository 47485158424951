import React from 'react';
import './footerContainer.css';

function FooterContainer(props) {

    return (
        <div id="footerContainer" className="container" >
            <div className="row" style={{ padding: "20px" }}>

                <div className="col-6 col-lg-3" style={{ alignSelf: "center" }} >
                    <img src="/images/Logo.png" alt="" height="33" />
                </div>
                <div className="col-6 col-lg-3" style={{ textAlign: "left" }}>
                    <div>
                        <i style={{ fontSize: "18px", }} className="fa fa-envelope" aria-hidden="true"></i>
                        &nbsp; &nbsp;	 <a href="mailto:support@ctserv.net"> support@ctserv.net</a>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <i style={{ fontSize: "18px", }} className="fa fa-phone" aria-hidden="true"></i>
                        &nbsp; &nbsp;	 <a style={window.innerWidth < 1045 ? {} : { borderRight: "1px solid #fff", paddingRight: "10px" }} href="tel:+9611202132"> +961-1-202132</a>
                        <a style={window.innerWidth < 1045 ? { paddingLeft: "35px", whiteSpace: "nowrap" } : { paddingLeft: "5px", whiteSpace: "nowrap" }} href="tel:+9619835600"> +961-9-835600</a>
                    </div>
                </div>
                <div className="col-6 col-lg-3" style={window.innerWidth > 991 ? { borderLeft: "2px solid #fff", alignSelf: "center" } : { marginTop: "20px", alignSelf: "center" }}>
                    <div>Follow us on Social Media</div>
                </div>
                <div className="col-6 col-lg-3" style={window.innerWidth > 991 ? { alignSelf: "center" } : { marginTop: "20px", alignSelf: "center" }}>
                    <div style={{ height: "19px", width: "19px", backgroundColor: "#fff", float: "left", borderRadius: "2px" }}>
                        <a href="https://www.linkedin.com/company/computer-technology-&amp;-services-c-t-serv-" target="_blank" rel="noreferrer">
                            <i style={{ color: "#0077b5", fontSize: "27px", marginTop: "-4px", marginLeft: "-2px" }} className="fa fa-linkedin-square" aria-hidden="true"></i>
                        </a>
                    </div>
                    {/* <div style={window.innerWidth>991?{ height: "19px", width: "19px", backgroundColor: "#fff", float: "left", marginLeft: "60px", borderRadius: "2px" }:{ height: "19px", width: "19px", backgroundColor: "#fff", float: "left", marginLeft: "20px", borderRadius: "2px" }}>
                        <a href="" target="_blank" rel="noreferrer">
                            <i style={{ color: "#0077b5", fontSize: "27px", marginTop: "-4px", marginLeft: "-2px" }} className="fa fa-facebook-square" aria-hidden="true"></i>
                        </a>
                    </div> */}
                    <div style={window.innerWidth>991?{ height: "19px", width: "19px", backgroundColor: "#fff", float: "left", marginLeft: "60px", borderRadius: "2px" }:{ height: "19px", width: "19px", backgroundColor: "#fff", float: "left", marginLeft: "20px", borderRadius: "2px" }}>
                        <a href="https://www.instagram.com/c.t.serv/" target="_blank" rel="noreferrer">
                            <i style={{ color: "#0077b5", fontSize: "27px", marginTop: "-4px", marginLeft: "-2px" }} className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FooterContainer;

