import React from 'react';
import ContactUsModal from '../contactUsModal/ContactUsModal';
import { Alert } from 'reactstrap';

import './contactUs.css';
import FooterContainer from '../footerContainer/FooterContainer';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  activateSuccessMsg = (message) => {
    this.setState({ message: message })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)
  }

  render() {
    let { message } = this.state
    return (
      <div className="container" >
        {message &&
          <Alert style={{ width: "100%", position: "absolute", zIndex: "100" }} color="success">
            {message}
          </Alert>
        }
        <div id="ContactUsModalMainContainer">

          <div className="row">
            <div className="col-sm-12 col-lg-6 contactBox">
              <h2>Ashrafieh (Headoffice)</h2>
              <p>CTServ building, Near Saint Coeurs School
                Sioufi, Ashrafieh Beirut Lebanon</p>

              <p><strong>Tel:</strong> <a href="tel:+9611202132">+961-1-202132</a> (5 lines)</p>
              <p><strong>Fax:</strong> 961-1-333950</p>
              <p><strong>P.O Box:</strong> 16-7154 Ashrafieh – Lebanon</p>
              <p><strong>E-mail:</strong><a href="mailto:support@ctserv.net"> support@ctserv.net</a></p>
            </div>
            <div className="col-sm-12 col-lg-6">
              <iframe style={{ border: 0, padding: "0 10px" }}
                title="Ctserv Ashrafieh location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26498.591915979807!2d35.5296952!3d33.8813088!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x10c923e115c189cf!2sCt%20Serv!5e0!3m2!1sar!2slb!4v1568295605119!5m2!1sar!2slb" width="100%" height="300" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-12 col-lg-6 contactBox">
              <h2>Jounieh</h2>
              <p>FAP building(2<sup>nd</sup>, 4<sup>th</sup> and 5<sup>th</sup> Floor)
                Jounieh Hightway, Lebanon</p>

              <p><strong>Tel:</strong> <a href="tel:+9619835600">+961-9-835600</a></p>
              <p><strong>Fax:</strong> 961-9-835600 or ext 201</p>
              <p><strong>P.O Box:</strong> 16-7154 Ashrafieh – Lebanon</p>
              <p><strong>E-mail:</strong><a href="mailto:support@ctserv.net"> support@ctserv.net</a></p>

            </div>
            <div className="col-sm-12 col-lg-6">
              <iframe style={{ border: 0, padding: "0 10px" }}
                title="Ctserv Jounieh Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.304610417074!2d35.64166988441883!3d33.98470882873285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f41dfc5b1b06b%3A0x23302dc6f63f813b!2sCTServ!5e0!3m2!1sar!2slb!4v1568295749388!5m2!1sar!2slb" width="100%" height="300" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
            </div>
          </div>
          <ContactUsModal
            type={"contactUs"}
            activateSuccessMsg={this.activateSuccessMsg}
            bottonStyle={{ width: "fit-content", padding: "5px 15px", borderRadius: "60px", fontSize: "15px" }}
            contactUsButtonStyle={window.innerWidth > 991 ? { } : { margin: "20px calc(50% - 85px)" }} />

        </div>
        <div id="AppFooterContainer">
          <FooterContainer />
        </div>
      </div>

    )
  }

}

export default ContactUs;

