import React from 'react';

import './privacyPolicy.css';

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div id="privacyPolicyContainer" className="container" >

                <div className="page-title"><strong><h1>Privacy Policy - Le Charcutier Rewards</h1></strong></div>
                <div className="content">
                    <div className="welcome"></div>
                    <div className="title"><p></p>
                    </div>

                    <div className="terms flex flex-row flex-wrap justify-start responsive">
                        <p><strong>Introduction</strong></p>
                        <p>This privacy policy explains how we collect, use, and protect the personal information of users who use "Le Charcutier Rewards" mobile application. We are committed to protecting your privacy and will only use your personal information in accordance with this policy and applicable data protection laws.</p>
                        <p><strong>Information we collect</strong></p>
                        <p>We collect personal information that you provide to us when you use the App, including your name and your phone number. We also collect information about your device and usage of the App, such as IP address, operating system, device model, and usage statistics. We may use cookies and other tracking technologies to collect information about your use of the App, such as pages visited and links clicked. This information is used exclusively to improve the App and personalize your experience.</p>
                        <p><strong>How we use your information</strong></p>
                        <p>We may use the information to respond to your inquiries, provide information about your loyatly card info and transactions.</p>
                        <p><strong>Disclosure of your information</strong></p>
                        <p>We do not share your personal information with third parties. We may disclose your personal information if required by law or if we believe that disclosure is necessary to protect our rights or the safety of our users or others.</p>
                        <p><strong>Data security</strong></p>
                        <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. We use encryption technology to protect data during transmission and implement security protocols to prevent unauthorized access to our servers. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                        <p><strong>User rights</strong></p>
                        <p>You have the right to access and correct your personal information, and to withdraw your consent to the use of your personal information. To exercise these rights, please contact us using the contact information provided at the end of this policy.</p>
                        <p><strong>Changes to this policy</strong></p>
                        <p>We may update this policy from time to time to reflect changes in our practices or applicable law. If we make material changes to this policy, we will notify you by posting a notice on the App or by other means. Your continued use of the App after any changes to this policy will constitute your acceptance of the changes.</p>
                        <p><strong>Contact us</strong></p>
                        <p>If you have any questions or concerns about this policy, please contact us at +961 1 694 724.</p>
                        <p>Last updated: 17-May-2023</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>

                </div>

            </div >
        )
    }

}

export default PrivacyPolicy;

