import React from 'react';
// import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"
// import { alphaencrypt } from '../../helpers/encryption';

import './contactUsModal.css';


class ContactUsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CorporateName: null,
            Country: "Lebanon",
            FullName: null,
            Email: null,
            Message: null,
            Subject: null,
            Software: "---",
            isOpen: false,

            mandatoryFields: [
                "Country",
                "FullName",
                "Email",
                "Message"
            ],

            tempMandatory: [],
            showErrorMessage: ''
        };
    }

    componentDidMount = () => {
        let mandatoryFields = cloneDeep(this.state.mandatoryFields)

        if (this.props.type === "contactUs")
            mandatoryFields.push("Subject")
        else if (this.props.type === "requestDemo")
            mandatoryFields.push("Software")
        this.setState({ mandatoryFields })
    }

    handleTextChange = val => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let { mandatoryFields } = this.state

        if (includes(mandatoryFields, val)) {
            tempMandatory = remove(tempMandatory, element => {
                return element !== val
            })
        }
        this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
    }


    sendMessage = () => {
        let { CorporateName, Country, FullName, Email, Message, Subject, Software } = this.state
        if (this.validateForm()) {

            document.getElementById("SpinnerMaincontainer").style.display = "block"
            let params = {
                email: Email,
                corpname: CorporateName,
                country: Country,
                name: FullName,
                message: Message,
            }

            if (this.props.type === "contactUs")
                params = { ...params, subject: Subject }
            else if (this.props.type === "requestDemo")
                params = { ...params, software: Software }

            const options = {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }

            fetch(`${window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/phpSendMail/php/'}helpers/phpmailer.php`, options)
                .then(res => {

                    return res.text()
                })
                .then(res => {
                    if (res === 'Message has been sent') {
                        document.getElementById("SpinnerMaincontainer").style.display = "none"
                        this.setState({ isOpen: false }, () => { this.props.activateSuccessMsg("Message Sent successfully") })
                    }
                    else
                        document.getElementById("SpinnerMaincontainer").style.display = "none"
                    this.setState({ showErrorMessage: 'Error While Sending Message, Please Try Later' })

                })
                .catch(err => {
                    document.getElementById("SpinnerMaincontainer").style.display = "none"
                    this.setState({ showErrorMessage: "Error While Sending Message, Please Try Later" })
                });




            // document.getElementById("SpinnerMaincontainer").style.display = "block"
            // const formData = new FormData();
            // formData.append("email", Email);
            // formData.append("corpname", CorporateName);
            // formData.append("country", Country);
            // formData.append("name", FullName)
            // formData.append("message", Message);

            // if (this.props.type === "contactUs")
            //     formData.append("subject", Subject);
            // else if (this.props.type === "requestDemo")
            //     formData.append("software", Software);

            // let d1 = (new Date()).getTime().toString() + "SendMailCTSERV";
            // axios({
            //     method: "post",
            //     url: `${'http://92.62.175.99/SendMail/Service1.svc/web/'}SendMail`,
            //     data: formData,
            //     headers: {
            //         Accept: "application/json",
            //         "Content-Type": "application/json",
            //         "authenticationInfo": alphaencrypt(d1)
            //     },
            // })
            //     .then(response => {
            //         if (response.data.SendMailResult === "OK") {
            //             document.getElementById("SpinnerMaincontainer").style.display = "none"
            //             this.setState({ isOpen: false }, () => { this.props.activateSuccessMsg("Message Sent successfully") })
            //         }
            //         else
            //             document.getElementById("SpinnerMaincontainer").style.display = "none"
            //         this.setState({ showErrorMessage: 'Error While Sending Message, Please Try Later' })
            //     })
            //     .catch(error => {
            //         if (axios.isCancel(error)) { console.log(error.message); }
            //         document.getElementById("SpinnerMaincontainer").style.display = "none"
            //         this.setState({ showErrorMessage: "Error While Sending Message, Please Try Later" })
            //     });
        }
    }

    validateForm = () => {
        let tempMandatory = (this.state.tempMandatory)
        map(this.state.mandatoryFields, eachElem => {
            if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)

            if (eachElem === "Email" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
            if (eachElem === "FullName" && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
        })

        this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

        setTimeout(() => {
            this.setState({ showErrorMessage: '' })
        }, 8000)
        if (!isEmpty(tempMandatory)) return false
        else return true
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
    }

    validateFullName = (fullName) => {
        const re = /[a-zA-Z]+$/;
        return !re.test(String(fullName).toLowerCase());
    }

    toggleModal = val => event => {
        this.setState({
            isOpen: val === "open" ? true : false,
            CorporateName: null,
            Country: "Lebanon",
            FullName: null,
            Email: null,
            Message: null,
            Subject: null,
            Software: "---",

            mandatoryFields: [
                "Country",
                "FullName",
                "Email",
                "Message"
            ],
            tempMandatory: [],
            showErrorMessage: ''
        })
    }

    render() {
        let { CorporateName, Country, FullName, Email, Message, Subject, Software, tempMandatory, showErrorMessage, isOpen } = this.state
        let { type, bottonStyle, contactUsButtonStyle } = this.props
        return (
            <>
                <div id="contactUsButton" style={contactUsButtonStyle?contactUsButtonStyle:{}}>
                        <Button color="primary" onClick={this.toggleModal("open")}style={bottonStyle?bottonStyle:{}}>
                            {type === "contactUs" && <h5>Contact Us</h5>}
                            {type === "requestDemo" && <h5>Request Demo</h5>}</Button>
                </div>

                <Modal isOpen={isOpen}  >
                    <ModalHeader>
                        {type === "contactUs" && <h3>Contact Us</h3>}
                        {type === "requestDemo" && <h3>Request Demo</h3>}

                    </ModalHeader>
                    <ModalBody>
                        {showErrorMessage &&
                            <Alert className="alertModal" color="danger">
                                {showErrorMessage}
                            </Alert>
                        }
                        <Form>

                            <FormGroup>
                                <Label >Corporate Name</Label>
                                <Input type="text" name="CorporateName" id="CorporateName"
                                    value={CorporateName ? CorporateName : ""}
                                    onChange={this.handleTextChange("CorporateName")} />
                            </FormGroup>

                            <FormGroup>
                                <Label className={includes(tempMandatory, "Country") ? "AlertRed" : ""}>Country *</Label>
                                <Input type="select" name="Country" id="Country"
                                    value={Country}
                                    className={includes(tempMandatory, "Country") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Country")}>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Korea">Korea</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                    <option value="Others">Others</option>

                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label className={includes(tempMandatory, "FullName") ? "AlertRed" : ""}>Full Name *</Label>
                                <Input type="text" name="FullName" id="FullName"
                                    value={FullName ? FullName : ""}
                                    className={includes(tempMandatory, "FullName") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("FullName")} />
                            </FormGroup>

                            <FormGroup>
                                <Label className={includes(tempMandatory, "Email") ? "AlertRed" : ""}>Email *</Label>
                                <Input type="email" name="Email" id="Email"
                                    value={Email ? Email : ""}
                                    className={includes(tempMandatory, "Email") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Email")} />
                            </FormGroup>
                            {type === "contactUs" &&
                                <FormGroup>
                                    <Label className={includes(tempMandatory, "Subject") ? "AlertRed" : ""} >Subject *</Label>
                                    <Input type="text" name="Subject" id="Subject"
                                        value={Subject ? Subject : ""}
                                        className={includes(tempMandatory, "Subject") ? "AlertRed" : ""}
                                        onChange={this.handleTextChange("Subject")} />
                                </FormGroup>
                            }
                            {type === "requestDemo" &&
                                <FormGroup>
                                    <Label className={includes(tempMandatory, "Software") ? "AlertRed" : ""}>Software* </Label>
                                    <Input type="select" name="Software" id="Software"
                                        value={Software}
                                        className={includes(tempMandatory, "Software") ? "AlertRed" : ""}
                                        onChange={this.handleTextChange("Software")}>
                                        <option value="All">---</option>
                                        <option value="Hospitals">Hospitals</option>
                                        <option value="MedicalCenters">Medical Centers</option>
                                        <option value="SupermarketsMinimarkets">Supermarkets / Minimarkets</option>
                                        <option value="ChainStores">Chain Stores (ERP)</option>
                                        <option value="PayrollHR">Payroll / HR</option>
                                    </Input>
                                </FormGroup>
                            }
                            <FormGroup>
                                <Label className={includes(tempMandatory, "Message") ? "AlertRed" : ""}>Message *</Label>
                                <Input type="textarea" name="Message" id="Message"
                                    value={Message ? Message : ""}
                                    className={includes(tempMandatory, "Message") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Message")} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.sendMessage}>Send</Button>
                        <Button color="secondary" onClick={this.toggleModal("close")}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }
}

export default ContactUsModal;

