import React from 'react';
import { map, cloneDeep } from "lodash"

import ImgSlider from './ImgSlider';
import './Activities.css';
import { getCtservNetData } from '../CtservNetData';
import { Button } from 'reactstrap';
import FooterContainer from '../footerContainer/FooterContainer';
class Activities extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ActivitieImgs: {}
    }
  }

  componentDidMount = () => {
    let data = getCtservNetData("Activities")
    let ActivitieImgs = {}
    map(data, eachElem => {
      ActivitieImgs = {
        ...ActivitieImgs,
        [eachElem.ActivitieCode]: eachElem.ActivitieImgs[0]
      }
    })
    this.setState({ data, ActivitieImgs })
  }

  handleChangeImg = (imgSource, ActivitieCode) => e => {
    let ActivitieImgs = cloneDeep(this.state.ActivitieImgs)
    ActivitieImgs = { ...ActivitieImgs, [ActivitieCode]: imgSource }
    this.setState({ ActivitieImgs })

  }

  render() {
    return (
      <div className="container" >
        <ImgSlider />
        <div id="activitiesSection" >
          {window.innerWidth > 767 ?
            <>
              {
                map(this.state.data, (eachDataElem, keys) => {
                  return (
                    <div className='activitiesInDesc row' key={eachDataElem.ActivitieCode + "-" + keys}>
                      <>
                        {keys % 2 !== 0 &&
                          <div className='col-12 col-md-6 activitiesInDescText'>
                            <h1>{eachDataElem.ActivitieTitle}</h1>
                            <p>{eachDataElem.ActivitieDescription}</p>
                          </div>
                        }

                        <div className='col-12 col-md-6'>
                          <div className='activitiesInDescMainImgContainer'>
                            <img src={this.state.ActivitieImgs[eachDataElem.ActivitieCode]} className='activitiesInDescMainImg' alt="" />
                          </div>
                          <div className='activitiesInDesSmallImgsMainContainer'>
                            <div className='activitiesInDesSmallImgsContainer'>
                              {
                                map(eachDataElem.ActivitieImgs, (eachElem, key) => {
                                  return (
                                    <Button key={key} onClick={this.handleChangeImg(eachElem, eachDataElem.ActivitieCode)} color='primary' className='ActivitieSmallImgsBtn'>
                                      <img src={eachElem} width="30px" height="30px" alt="" />
                                    </Button>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                        {keys % 2 === 0 &&
                          <div className='col-12 col-md-6 activitiesInDescText' >
                            <h1>{eachDataElem.ActivitieTitle}</h1>
                            <p>{eachDataElem.ActivitieDescription}</p>
                          </div>
                        }
                      </>
                    </div>
                  )
                })
              }
            </>
            :
            <>
             {
                map(this.state.data, (eachDataElem, keys) => {
                  return (
                    <div className='activitiesInDesc row' key={eachDataElem.ActivitieCode + "-" + keys}>
                      <>
                        
                          <div className='col-12 col-md-8'>
                            <h1>{eachDataElem.ActivitieTitle}</h1>
                            <p>{eachDataElem.ActivitieDescription}</p>
                          </div>
                
                        <div className='col-12 col-md-4'>
                          <div className='activitiesInDescMainImgContainer'>
                            <img src={this.state.ActivitieImgs[eachDataElem.ActivitieCode]} className='activitiesInDescMainImg' alt="" />
                          </div>
                          <div className='activitiesInDesSmallImgsMainContainer'>
                            <div className='activitiesInDesSmallImgsContainer'>
                              {
                                map(eachDataElem.ActivitieImgs, (eachElem, key) => {
                                  return (
                                    <Button key={key} onClick={this.handleChangeImg(eachElem, eachDataElem.ActivitieCode)} color='primary' className='ActivitieSmallImgsBtn'>
                                      <img src={eachElem} width="30px" height="30px" alt="" />
                                    </Button>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  )
                })
              }
            </>
          }
        </div>
          <div id="AppFooterContainer">
          <FooterContainer />
        </div>
      </div>
    )
  }
}

export default Activities;



