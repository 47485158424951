import React from 'react';
import { Alert } from 'reactstrap';

import './companyOverview.css';
import CompanyOverviewTab from './CompanyOverviewTab';
import FooterContainer from '../footerContainer/FooterContainer';

class CompanyOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  activateSuccessMsg = (message) => {
    this.setState({ message: message })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)
  }

  render() {
    let { message } = this.state
    return (
      <div className="container" >
        {message &&
          <Alert style={{ width: "calc(100% - 47px)", position: "absolute", zIndex: "100" }} color="success">
            {message}
          </Alert>
        }
        <div id="Our-esteemed" >
          {/* <h5>Our esteemed</h5> */}
          <h1>Company Overview</h1>
        </div>

        <div id="companyOverviewContainer">
          <div className="row">
            {/* <div className="col-sm-12 col-lg-6">
              <img src="/images/aboutUs/AboutUs.jpg" alt="" width="100%" height="auto" />
            </div> */}
            <div className="col-12">
              <CompanyOverviewTab />
            </div>
          </div>
          {/* <ContactUsModal
            type="contactUs"
            activateSuccessMsg={this.activateSuccessMsg} /> */}
        </div>
          <div id="AppFooterContainer">
          <FooterContainer />
        </div>
      </div>
    )
  }
}

export default CompanyOverview;

