export function getCtservNetData(val) {
    let returnData
    switch (val) {
        case "Hospitals":
            returnData =
            {
                imageSourse: "/images/hospitals/MileraHospitalsBanner.jpg",
                data: [
                    {
                    id: "hospitalsHos-EMR",
                    icon: "/images/hospitals/icons/Hos-EMR.png",
                    title: "Electronic Medical Record – E.M.R",
                    header: "Our Newest Release!",
                    descriptionPara: ["The Electronic Medical Record Solution is built on the pillars of Patient-Centered Care, Safety & Quality. It was developed by Healthcare Professionals to Healthcare Professionals. Its main purpose: enhance modern healthcare for both patients and practitioners. It is a fully mobile platform that allows unprecedented access to patient records anytime anywhere. The Milera EMR is a medical platform that bridges the medical world with the institution’s administrative & billing cycle (HIS) in processes that are 100% automated.",
                        "The EMR is designed to centralize health records. From patient information, medical histories, charts, clinical documentation, prescriptions to medical specialties which include and are not limited to: Surgery, Endocrinology, Transfusion Records, Dietary Records, etc.",
                        "No more tracking lost charts."],
                    firstSection: [
                        "CONFORM TO INTERNATIONAL STANDARDS (SNOMED, CPT, LOINC, ETC)",
                        "FULLY MOBILE",
                        "ONLINE ACCESS ON-PREMISES OR OFF-PREMISES",
                        "100% INTEGRATED WITH BILLING CYCLE (HIS)",
                        "FACIAL RECOGNITION & FINGERPRINT LOGIN",
                        "HIGH SECURITY STANDARDS TO PROTECT PATIENT DATA",
                        "MODULAR APPLICATION",
                        "VERY EASY & USER-FRIENDLY INTERFACE",
                        "PROFILE BY GROUPS/USERS",
                        "COMPLETE TRACKING OF USER ACTIVITY",
                    ],
                    secondSection: [
                        "COMPUTERIZED ORDER ENTRIES (CPOE)",
                        "INTEGRATION WITH DRUG REFERENCE DATABASES",
                        "ORDER SETS",
                        "TASK VIEW & TRACKING",
                        "CO-SIGNATURE PROCESS",
                        "LIVE UPDATE ON PATIENT INFORMATION",
                        "FULL ACCESS TO PATIENT RESULTS",
                        "COMPLETE ACCESS TO PATIENT ARCHIVING",
                        "FULL VIEW OF PATIENT TIMELINE",
                        "ISOLATION/INFECTION PROCESS",
                    ],
                    thirdSection: [
                        "PATIENT WEB PORTAL",
                        "AUTOMATIC GENERATION OF INSTITUTION FORMS)",
                        "NOTES (ALL MARKUP, NO MARKUP, ORIGINAL)",
                        "INTERACTIVE PATIENT LIST",
                        "MANAGEMENT OF BILLING & REIMBURSEMENT",
                        "SMART ALERT & MESSAGING SYSTEM",
                        "CLINICAL DECISION SUPPORT",
                        "INTERACTIVE VIEW OF PATIENT CLINICAL PATH",
                        "PREVIOUS ENCOUNTERS",
                        "LINK WITH MEDICAL MACHINES (EX. VITAL SIGNS, MONITORS, ETC.)",
                    ]
                },
                {
                    id: "hospitalsHos-HIS",
                    icon: "/images/hospitals/icons/Hos-HIS.png",
                    title: "Hospital Information System – H.I.S.",
                    header: "",
                    descriptionPara: ["The H.I.S. is a fully integrated solution which allows Small, Medium and Large Hospitals to gain competitive advantage by streamlining most of your hospital’s business processes that are otherwise labor-intensive and costly.",
                        "The Hospital Information System (H.I.S.) was built on the pillars of security and centralized information. It covers the main functionalities of your processes including, but not limited to, Patient Admission, Floor Management, Departmental Transactions, Billing & Collection.",
                        "It can also be complemented with important add-on modules that complete the range of features that will benefit your hospital."],
                    firstSection: [
                        "COVERAGE & PRICE LISTS",
                        "ADMISSION",
                        "FLOOR / RESIDENCE",
                        "LABORATORY & LAC",
                        "RADIOLOGY & RIS SCHEDULING",
                        "GENERAL EXAMS",
                        "OPERATIONS & OR SCHEDULING",
                        "DOCTORS MODULE",
                        "PHARMACY & GENERAL STOCK",
                        "CASHIER",
                        "INVOICING",
                    ],
                    secondSection: [
                        "COLLECTION",
                        "MEDICAL COMMITTEE & SEPARATED DOCTOR FEES",
                        "OFFICIAL REPORTING TO GUARANTORS",
                        "STATISTICS",
                        "BEDS PLAN MANAGEMENT",
                        "PATIENT CARD",
                        "CONSIGNMENT",
                        "PROCUREMENT",
                        "WORKFLOW MODULE",
                        "GENERAL ARCHIVING",
                        "AUDIT MODULE"
                    ],
                    thirdSection: [
                        "PATIENT FILE CIRCULATION",
                        "INSURANCE WEB PORTAL",
                        "ADMISSION CHECKLIST",
                        "VACATION MANAGEMENT",
                        "CRM",
                        "LONG STAY MODULE",
                        "EASY SCHEDULE",
                        "PBX TELEPHONE INVOICING",
                        "E-BILLING TO PUBLIC COVERAGE",
                        "E-BILLING TO PRIVATE COVERAGE",
                        'DOCTORS "MY ACCOUNT WEB"',
                    ]
                },
                {
                    id: "hospitalsHos-LIS",
                    icon: "/images/hospitals/icons/Hos-LIS.png",
                    title: "Laboratory Information System – L.I.S.",
                    header: "",
                    descriptionPara: ["The L.I.S is a laboratory management software that helps users complete their various tasks for labs of all sizes. It is designed for multi-specialties laboratories. It assists medical labs in efficiently managing lab orders and tracking them, overseeing patient records and attaining results history. This solution helps users automate manual work, link to machines, as well as verify and secure results.",
                        "Our LAC manages all analyzers in the lab in an automated process with load balancing for better and safer performance."],
                    firstSection: [
                        "LAB EXAMS DEFINITION",
                        "LABORATORY ANALYZER CONTROLLER (LAC)",
                        "LOAD BALANCING",
                    ],
                    secondSection: [
                        "DASHBOARD",
                        "AUDIT & CONTROL ANALYZERS",
                        "VERIFICATION & LOCK OF RESULTS",
                    ],
                    thirdSection: [
                        "CRITICAL RESULTS ALERT",
                        "RESULTS HISTORY",
                        "LAB WEB PORTAL",
                    ]
                },
                {
                    id: "hospitalsHos-Clinical",
                    icon: "/images/hospitals/icons/Hos-Clinical.png",
                    title: "Clinical",
                    header: "",
                    descriptionPara: ["In addition to the administrative operations of hospitals & clinics, Milera provides medical solutions that streamline processes & save time. Patients files are now digital to improve collaboration and secure access to medical history, medications, charts & treatment plans."
                        , "Milera Clinical understands that patients are the center of care, their medical files are their identity, and the digital solution is the tool any healthcare team needs to treat them safely, fast & efficiently."],
                    firstSection: [
                        "ELECTRONIC MEDICAL RECORD",
                        "PATIENT SUMMARY FILE",
                        "FULL PATIENT ELECTRONIC ARCHIVING",
                        "PATIENT & DOCTORS WEB PORTAL",
                        "BLOOD BANK MANAGEMENT",
                    ],
                    secondSection: [
                        "OR SCHEDULING",
                        "PRE-ADMINISSION UNIT",
                        "DRUGS MANAGEMENT & ADMINISTRATION",
                        "PATIENT CARE MODULE",
                        "SCAN DOCTOR ORDER SHEET"
                    ],
                    thirdSection: [
                        "ISOLATION & INFECTION CONTROL",
                        "EXTERNAL CLINICS MANAGEMENT",
                        "DIABETES",
                        "IET MANAGEMENT",
                        "ACS/RIS INTERFACE"
                    ],
                },
                {
                    id: "hospitalsHos-Payroll",
                    icon: "/images/hospitals/icons/Hos-Payroll.png",
                    title: "Payroll/HR",
                    header: "",
                    descriptionPara: ["The purpose behind the Payroll/HR application is to help you efficiently manage your most valuable asset – your labor force. Controlling costs, improving productivity, evaluating performance, all becomes an easy task that will in turn benefit your business.",
                    "Payroll/HR gives you access to all this information from a centralized source in order to generate necessary reports and take managerial decisions."],
                    firstSection: [
                        "FULL PAYROLL COMPUTATION",
                        "PAY SLIPS & PAYMENT SLIPS",
                        "AUTOMATED BANK PAYMENTS",
                        "BENEFITS & DEDUCTION MANAGEMENT",
                        "EMPLOYEE ID CARD PRINTING",
                        "COMPANY WORK RULES",
                    ],
                    secondSection: [
                        "ATTENDANCE MANAGEMENT",
                        "EMPLOYEES SCHEDULE MANAGEMENT",
                        "VACATION MANAGEMENT",
                        "OFFICIAL REPORTING R3, R4, R5, R6, R10",
                        "CNSS OFFICIAL REPORTS & WORKERS REGISTER",
                        "END OF SERVICE REPORTS",
                    ],
                    thirdSection: [
                        "HR WEB PORTAL",
                        "ONLINE EMPLOYMENT MANAGEMENT",
                        "APPRAISAL MANAGEMENT",
                        "TRAINING MODULE",
                        "PERSONNEL ALLOCATION",
                        "CERTIFICATES & ATTESTATIONS",
                    ]
                },
                {
                    id: "hospitalsHos-Financial",
                    icon: "/images/hospitals/icons/Hos-Financial.png",
                    title: "Financial",
                    header: "",
                    descriptionPara: ["The Financial Application offers a complete solution that will enable you to get rid of the manual processes and replace them with a reliable reporting and business analytical tool.",
                    "The different modules combined with the integrated information source will help you have a clear strategic vision while saving significant amount of labor inputs and unnecessary errors"],
                    firstSection: [
                        "GENERAL ACCOUNTING",
                        "MULTIPLE COMPANIES HANDLING",
                        "VAT MANAGEMENT",
                        "PAYMENTS & BANK RECONCILIATION",
                    ],
                    secondSection: [
                        "ACCOUNT RECEIVABLES/PAYABLES",
                        "CASH PROVISION",
                        "COST CENTER MANAGEMENT",
                        "CHECKS MODULE",
                    ],
                    thirdSection: [
                        "FIXED ASSETS",
                        "MAINTENANCE & SERVICE MODULE",
                        "PROFIT & LOSS STATEMENTS",
                        "BALANCE SHEET",
                    ]
                },
                {
                    id: "hospitalsHos-Intelligence",
                    icon: "/images/hospitals/icons/Hos-Intelligence.png",
                    title: "Intelligence",
                    header: "",
                    descriptionPara: ["Allow our Business Intelligence module to analyze your data and provide important information for you to take action. The solution gives you historical, current and predictive insight of your business operations to allow your executives and managers to see trends and make strategic business decisions.",
                    "The Intelligence module goes beyond analyzing existing data. It has the power to predict possible consumer behavior through data mining."],
                    firstSection: [
                        "BUSINESS INTELLIGENCE",
                        "DATA WAREHOUSE",
                    ],
                    secondSection: [
                        "CUBES MANAGEMENT",
                        "COST ACCOUNTING",
                    ],
                    thirdSection: [
                        "INFINITE REPORTS",
                        "FULLY INTEGRATED WITH REPORTING TOOLS",
                    ]
                },
                {
                    id: "hospitalsHos-Admin",
                    icon: "/images/hospitals/icons/Hos-Admin.png",
                    title: "Admin",
                    header: "",
                    descriptionPara: [],
                    firstSection: [
                        "FULL SECURITY & LOGGING",
                        "USERS & GROUP PRIVILEGES",
                        "AUTHORIZATION TO SPECIAL OPERATIONS",
                        "INTERNATIONAL SECURITY STANDARDS",
                        "FULL TRACKING OF USERS ACCESS",
                    ],
                    secondSection: [
                        "DATA INTEGRITY PRESERVATION",
                        "HIGH AVAILABILITY & CLUSTERED SOLUTION",
                        "FULL INTEGRATION OF MODULES",
                        "ONLINE HELP & TRAINING MANUAL",
                        "PERIODICAL UPDATE OF SYSTEM VERSIONS",
                    ],
                    thirdSection: [
                        "24/7 SUPPORT",
                        "PROACTIVE FRONT-LINE SUPPORT & FOLLOW UP",
                        "ONSITE & REMOTE CUSTOMER SUPPORT",
                        "COMPREHENSIVE INITIAL TRAINING",
                        "CONTINUOUS TRAINING ENVIRONMENT",
                    ]
                }]
            }
            break;

        case "MedicalCenter":
            returnData =
            {
                imageSourse: "/images/medicalCenters/MileraMedicalCentersBanner.jpg",
                data: [{
                    id: "medicalCentersCC-MCM",
                    icon: "/images/medicalCenters/icons/CC-MCM.png",
                    title: "Medical Centers Management – M.C.M.",
                    header: "",
                    descriptionPara: ["The M.C.M is a fully integrated solution for Medical Centers (Laboratory & Radiology) which allows them to gain competitive advantage by streamlining their  business processes that are otherwise labor-intensive and costly. It is built on the pillars of security and centralized information. The M.C.M can also be complemented with important add-on modules that complete the range of features that will benefit your center."],
                    firstSection: [
                        "COVERAGE & PRICE LISTS",
                        "ADMISSION",
                        "LABORATORY & LAC",
                        "RADIOLOGY & RIS SCHEDULING",
                        "GENERAL EXAMS",
                        "DOCTORS MODULE",
                        "GENERAL STOCK",
                        "CASHIER",
                    ],
                    secondSection: [
                        "INVOICING",
                        "COLLECTION",
                        "OFFICIAL REPORTING TO GUARANTORS",
                        "STATISTICS",
                        "PATIENT CARD",
                        "PATIENT SUMMARY FILE",
                        "PATIENT & DOCTOR WEB PORTAL",
                        "BLOOD BANK",
                    ],
                    thirdSection: [
                        "PROCUREMENT",
                        "WORKFLOW MODULE",
                        "FULL PATIENT ELECTRONIC ARCHIVING",
                        "INSURANCE WEB PORTAL",
                        "E-BILLING TO PUBLIC COVERAGE",
                        "E-BILLING TO PRIVATE COVERAGE",
                        'DOCTORS "MY ACCOUNT WEB"',
                        "VACATION MANAGEMENT",
                    ]
                },
                {
                    id: "medicalCentersCC-LIS",
                    icon: "/images/medicalCenters/icons/CC-LIS.png",
                    title: "Laboratory Information System – L.I.S.",
                    header: "",
                    descriptionPara: ["The L.I.S is a laboratory management software that helps users complete their various tasks for labs of all sizes. It is designed for multi-specialties laboratories. It assists medical labs in efficiently managing lab orders and tracking them, overseeing patient records and attaining results history. This solution helps users automate manual work, link to machines, as well as verify and secure results.",
                        "Our LAC manages all analyzers in the lab in an automated process with load balancing for better and safer performance."],
                    firstSection: [
                        "LAB EXAMS DEFINITION",
                        "LABORATORY ANALYZER CONTROLLER (LAC)",
                        "LOAD BALANCING",
                    ],
                    secondSection: [
                        "DASHBOARD",
                        "AUDIT & CONTROL ANALYZERS",
                        "VERIFICATION & LOCK OF RESULTS",
                    ],
                    thirdSection: [
                        "CRITICAL RESULTS ALERT",
                        "RESULTS HISTORY",
                        "LAB WEB PORTAL",
                    ]
                },
                {
                    id: "medicalCentersCC-RIS",
                    icon: "/images/medicalCenters/icons/CC-RIS.png",
                    title: "Radiology Information System – R.I.S.",
                    header: "",
                    descriptionPara: ["The R.I.S is a radiology scheduling software that helps users complete their various tasks for radiology centers of all sizes. It is designed to assist radiology centers in efficiently managing schedules, overseeing patient records and connecting to various radiology machines.",
                    "This solution helps users automate manual work as well as verify and secure results."],
                    firstSection: [
                        "RADIOLOGY EXAMS DEFINITIONS",
                        "RIS SCHEDULING",
                    ],
                    secondSection: [
                        "PACS",
                        "VERIFICATION & LOCK OF RESULTS",
                    ],
                    thirdSection: [
                        "RESULTS HISTORY",
                        "RADIO WEB PORTAL",
                    ]
                },
                {
                    id: "medicalCentersCC-Payroll",
                    icon: "/images/medicalCenters/icons/CC-Payroll.png",
                    title: "Payroll/HR",
                    header: "",
                    descriptionPara: ["The purpose behind the Payroll/HR application is to help you efficiently manage your most valuable asset – your labor force. Controlling costs, improving productivity, evaluating performance, all becomes an easy task that will in turn benefit your business.",
                    "Payroll/HR gives you access to all this information from a centralized source in order to generate necessary reports and take managerial decisions."],
                    firstSection: [
                        "FULL PAYROLL COMPUTATION",
                        "PAY SLIPS & PAYMENT SLIPS",
                        "AUTOMATED BANK PAYMENTS",
                        "BENEFITS & DEDUCTION MANAGEMENT",
                        "EMPLOYEE ID CARD PRINTING",
                        "COMPANY WORK RULES",
                    ],
                    secondSection: [
                        "ATTENDANCE MANAGEMENT",
                        "EMPLOYEES SCHEDULE MANAGEMENT",
                        "VACATION MANAGEMENT",
                        "OFFICIAL REPORTING R3, R4, R5, R6, R10",
                        "CNSS OFFICIAL REPORTS & WORKERS REGISTER",
                        "END OF SERVICE REPORTS",
                    ],
                    thirdSection: [
                        "HR WEB PORTAL",
                        "ONLINE EMPLOYMENT MANAGEMENT",
                        "APPRAISAL MANAGEMENT",
                        "TRAINING MODULE",
                        "PERSONNEL ALLOCATION",
                        "CERTIFICATES & ATTESTATIONS",
                    ]
                },
                {
                    id: "medicalCentersCC-Financial",
                    icon: "/images/medicalCenters/icons/CC-Financial.png",
                    title: "Financial",
                    header: "",
                    descriptionPara: ["The Financial Application offers a complete solution that will enable you to get rid of the manual processes and replace them with a reliable reporting and business analytical tool.",
                    "The different modules combined with the integrated information source will help you have a clear strategic vision while saving significant amount of labor inputs and unnecessary errors"],
                    firstSection: [

                        "GENERAL ACCOUNTING",
                        "MULTIPLE COMPANIES HANDLING",
                        "VAT MANAGEMENT",
                        "PAYMENTS & BANK RECONCILIATION",
                    ],
                    secondSection: [
                        "ACCOUNT RECEIVABLES/PAYABLES",
                        "CASH PROVISION",
                        "COST CENTER MANAGEMENT",
                        "CHECKS MODULE",
                    ],
                    thirdSection: [
                        "FIXED ASSETS",
                        "MAINTENANCE & SERVICE MODULE",
                        "PROFIT & LOSS STATEMENTS",
                        "BALANCE SHEET",
                    ]
                },
                {
                    id: "medicalCentersCC-Intelligence",
                    icon: "/images/medicalCenters/icons/CC-Intelligence.png",
                    title: "Intelligence",
                    header: "",
                    descriptionPara: ["Allow our Business Intelligence module to analyze your data and provide important information for you to take action. The solution gives you historical, current and predictive insight of your business operations to allow your executives and managers to see trends and make strategic business decisions. The Intelligence module goes beyond analyzing existing data.",
                    "It has the power to predict possible consumer behavior through data mining."],
                    firstSection: [
                        "BUSINESS INTELLIGENCE",
                        "DATA WAREHOUSE",
                    ],
                    secondSection: [
                        "CUBES MANAGEMENT",
                        "COST ACCOUNTING",
                    ],
                    thirdSection: [
                        "INFINITE REPORTS",
                        "FULLY INTEGRATED WITH REPORTING TOOLS",
                    ]
                },
                {
                    id: "medicalCentersCC-Admin",
                    icon: "/images/medicalCenters/icons/CC-Admin.png",
                    title: "Admin",
                    header: "",
                    descriptionPara: [],
                    firstSection: [
                        "FULL SECURITY & LOGGING",
                        "USERS & GROUP PRIVILEGES",
                        "AUTHORIZATION TO SPECIAL OPERATIONS",
                        "INTERNATIONAL SECURITY STANDARDS",
                        "FULL TRACKING OF USERS ACCESS",
                    ],
                    secondSection: [
                        "DATA INTEGRITY PRESERVATION",
                        "HIGH AVAILABILITY & CLUSTERED SOLUTION",
                        "FULL INTEGRATION OF MODULES",
                        "ONLINE HELP & TRAINING MANUAL",
                        "PERIODICAL UPDATE OF SYSTEM VERSIONS",
                    ],
                    thirdSection: [
                        "24/7 SUPPORT",
                        "PROACTIVE FRONT-LINE SUPPORT & FOLLOW UP",
                        "ONSITE & REMOTE CUSTOMER SUPPORT",
                        "COMPREHENSIVE INITIAL TRAINING",
                        "CONTINUOUS TRAINING ENVIRONMENT",
                    ]
                },
                ]
            }
            break;

        case "SupermarketsMinimarkets":
            returnData =
            {
                imageSourse: "/images/supermarkets/MangoBanner.jpg",
                data: [
                    {
                        id: "supermarketssup-POS",
                        icon: "/images/supermarkets/icons/sup-POS.png",
                        title: "POS",
                        header: "",
                        descriptionPara: ["Mango Retail is an integrated solution which allows Supermarkets & Minimarkets of any size to optimize cost, enhance customer experience and automate business processes. 980 POS are currently running our Software. Its features include front-end and back-end operations in order to provide you with a complete solution to your business needs.",
                        "Mango is offered as a basic pack which can be complemented with add-on modules that will further improve your digital experience."],
                        firstSection: [
                            "POS MODULE",
                            "PRIVILEGE CARD (POINTS/CASHBACK)",
                            "MULTIPLE REGISTERS",
                        ],
                        secondSection: [
                            "CONCURRENT SESSIONS",
                            "MULTIPLE PAYMENT PROCESSING",
                            "REFUNDS CONTROL",
                        ],
                        thirdSection: [
                            "DISCOUNTS CONTROL",
                            "OPERATION CONTROL",
                            "STAND-ALONE & CONNECTED OPERATIONS",
                        ]
                    },
                    {
                        id: "supermarketssup-BackOffice",
                        icon: "/images/supermarkets/icons/sup-BackOffice.png",
                        title: "Back Office",
                        header: "",
                        descriptionPara: ["Mango Back Office streamlines operations, increases data accuracy, transforms your operations, and raises service levels.",
                        "Its consolidated and centralized data centers allows you to see the BIG picture. It is the backbone of Mango POS that controls and runs all operations."],
                        firstSection: [
                            "BACK OFFICE MANAGEMENT",
                            "PURCHASING",
                            "SUPPLIER CONTRACT",
                            "BARCODE MANAGEMENT",
                            "PRICING MANAGEMENT",
                        ],
                        secondSection: [
                            "CONSOLIDATION MODULE",
                            "PREPAID ACCOUNT MANAGEMENT",
                            "SCALES CONNECTION MODULE",
                            "PHYSICAL COUNT",
                            "WAREHOUSE RECEIVING",
                        ],
                        thirdSection: [
                            "PRODUCTION",
                            "DELIVERY MODULE",
                            "SHOW PRICE MODULE",
                            "SPACE RENTING",
                            "RESTAURANTS / CAFÉ MANAGEMENT",
                        ]
                    },
                    {
                        id: "supermarketssup-Signage",
                        icon: "/images/supermarkets/icons/sup-Signage.png",
                        title: "Digital Signage",
                        header: "",
                        descriptionPara: ["Mango Digital Signage is a solution that assists your business with content management and customer targeting. It helps increase customers engagement and exposure. Manage your ads by editing images, content, graphics, and videos yourself."],
                        firstSection: [
                            "INTERACTIVE DISPLAY",
                            "ADVERTISING TOOL",
                        ],
                        secondSection: [
                            "DISPLAY IMAGES, VIDEOS, GRAPHICS, ETC.",
                            "INCREASE CUSTOMER EXPOSURE",
                        ],
                        thirdSection: [
                            "EASY TO MANAGE",
                            "ENHANCE AUDIENCE EXPERIENCE",
                        ]
                    },
                    {
                        id: "supermarketssup-Payroll",
                        icon: "/images/supermarkets/icons/sup-Payroll.png",
                        title: "Payroll/HR",
                        header: "",
                        descriptionPara: ["The purpose behind the Payroll/HR application is to help you efficiently manage your most valuable asset – your labor force. Controlling costs, improving productivity, evaluating performance, all becomes an easy task that will in turn benefit your business.",
                        "Payroll/HR gives you access to all this information from a centralized source in order to generate necessary reports and take managerial decisions."],
                        firstSection: [
                            "FULL PAYROLL COMPUTATION",
                            "PAY SLIPS & PAYMENT SLIPS",
                            "AUTOMATED BANK PAYMENTS",
                            "BENEFITS & DEDUCTION MANAGEMENT",
                            "EMPLOYEE ID CARD PRINTING",
                            "COMPANY WORK RULES",
                        ],
                        secondSection: [
                            "ATTENDANCE MANAGEMENT",
                            "EMPLOYEES SCHEDULE MANAGEMENT",
                            "VACATION MANAGEMENT",
                            "OFFICIAL REPORTING R3, R4, R5, R6, R10",
                            "CNSS OFFICIAL REPORTS & WORKERS REGISTER",
                            "END OF SERVICE REPORTS",
                        ],
                        thirdSection: [
                            "HR WEB PORTAL",
                            "ONLINE EMPLOYMENT MANAGEMENT",
                            "APPRAISAL MANAGEMENT",
                            "TRAINING MODULE",
                            "PERSONNEL ALLOCATION",
                            "CERTIFICATES & ATTESTATIONS",
                        ]
                    },
                    {
                        id: "supermarketssup-Financial",
                        icon: "/images/supermarkets/icons/sup-Financial.png",
                        title: "Financial",
                        header: "",
                        descriptionPara: ["The Financial Application offers a complete solution that will enable you to get rid of the manual processes and replace them with a reliable reporting and business analytical tool.",
                        "The different modules combined with the integrated information source will help you have a clear strategic vision while saving significant amount of labor inputs and unnecessary errors"],
                        firstSection: [
                            "GENERAL ACCOUNTING",
                            "MULTIPLE COMPANIES HANDLING",
                            "VAT MANAGEMENT",
                            "PAYMENTS & BANK RECONCILIATION",
                        ],
                        secondSection: [
                            "ACCOUNT RECEIVABLES/PAYABLES",
                            "CASH PROVISION",
                            "COST CENTER MANAGEMENT",
                            "CHECKS MODULE",
                        ],
                        thirdSection: [
                            "FIXED ASSETS",
                            "MAINTENANCE & SERVICE MODULE",
                            "PROFIT & LOSS STATEMENTS",
                            "BALANCE SHEET",
                        ]
                    },
                    {
                        id: "supermarketssup-Intelligence",
                        icon: "/images/supermarkets/icons/sup-Intelligence.png",
                        title: "Intelligence",
                        header: "",
                        descriptionPara: ["Allow our Business Intelligence module to analyze your data and provide important information for you to take action. The solution gives you historical, current and predictive insight of your business operations to allow your executives and managers to see trends and make strategic business decisions. The Intelligence module goes beyond analyzing existing data.",
                        "It has the power to predict possible consumer behavior through data mining."],
                        firstSection: [
                            "BUSINESS INTELLIGENCE",
                            "DATA WAREHOUSE",
                        ],
                        secondSection: [
                            "CUBES MANAGEMENT",
                            "COST ACCOUNTING"
                        ],
                        thirdSection: [
                            "INFINITE REPORTS",
                            "FULLY INTEGRATED WITH REPORTING TOOLS",

                        ]
                    },
                    {
                        id: "supermarketssup-Admin",
                        icon: "/images/supermarkets/icons/sup-Admin.png",
                        title: "Admin",
                        header: "",
                        descriptionPara: [],
                        firstSection: [
                            "FULL SECURITY & LOGGING",
                            "USERS & GROUP PRIVILEGES",
                            "AUTHORIZATION TO SPECIAL OPERATIONS",
                            "INTERNATIONAL SECURITY STANDARDS",
                            "FULL TRACKING OF USERS ACCESS",
                        ],
                        secondSection: [
                            "DATA INTEGRITY PRESERVATION",
                            "HIGH AVAILABILITY & CLUSTERED SOLUTION",
                            "FULL INTEGRATION OF MODULES",
                            "ONLINE HELP & TRAINING MANUAL",
                            "PERIODICAL UPDATE OF SYSTEM VERSIONS",
                        ],
                        thirdSection: [
                            "24/7 SUPPORT",
                            "PROACTIVE FRONT-LINE SUPPORT & FOLLOW UP",
                            "ONSITE & REMOTE CUSTOMER SUPPORT",
                            "COMPREHENSIVE INITIAL TRAINING",
                            "CONTINUOUS TRAINING ENVIRONMENT",]
                    },]
            }
            break;

        case "ChainStores":
            returnData =
            {
                imageSourse: "/images/chinStore/ScalaBanner2.jpg",
                data: [{
                    id: "chinStoreERP-ERP",
                    icon: "/images/chinStore/icons/ERP-ERP.png",
                    title: "ERP",
                    header: "",
                    descriptionPara: ["Scala Enterprise has one main purpose: Allow your chain store to have a real-time view of your business as a whole. It is a solution made to integrate your business operations in order to reduce errors, ensure security, increase cost efficiency, and improve labor productivity.",
                    "Scala is fully featured for General Trading, Fashion Shops, Bookshops, Service Centers, Retail Businesses, and Production. This solution adapts to Your Business!"],
                    firstSection: [
                        "WHOLESALE & RETAIL",
                        "MULTIPLE BRANCH MANAGEMENT",
                        "INTER-BRANCH MANAGEMENT",
                        "CONSOLIDATION MODULE",
                        "PURCHASING",
                        "DISTRIBUTION",
                        "EXPIRY DATE CONTROL",
                    ],
                    secondSection: [
                        "BACK OFFICE MANAGEMENT",
                        "PRICING MANAGEMENT",
                        "CONSIGNMENT",
                        "PHYSICAL COUNT",
                        "BARCODE MANAGEMENT",
                        "WAREHOUSE MANAGEMENT",
                        "SALES INCENTIVE MANAGEMENT",
                    ],
                    thirdSection: [
                        "MOBILE CLIENT ORDERS",
                        "MOBILE COLLECTION",
                        "CASH VAN",
                        "TELEPHONE INDEX / MAILING LIST",
                        "SPACE RENTING",
                        "BOOKS LIBRARY MANAGEMENT",
                        "PRIVILEGE CARD",
                    ],
                },
                {
                    id: "chinStoreERP-Production",
                    icon: "/images/chinStore/icons/ERP-Production.png",
                    title: "Production",
                    header: "",
                    descriptionPara: ["Scala Production is an integrated solution for your factory that helps you manage all stages of an operation, including product planning, development, and manufacturing processes.",
                    "It streamlines processes across departments, and optimizes operational efficiency to make better decisions faster."],
                    firstSection: [
                        "RAW MATERIAL MANAGEMENT",
                        "PRODUCTION CARD AND RECIPE MANAGEMENT",
                    ],
                    secondSection: [
                        "PRODUCT PLANNING",
                        "MANUFACTURING PROCESS CONTROL",
                    ],
                    thirdSection: [
                        "BATCH PROCESSING AND LOT # CONTROL",
                        "PRODUCTION VARIANCE REPORT",
                    ]
                },
                {
                    id: "chinStoreERP-Franchise",
                    icon: "/images/chinStore/icons/ERP-Franchise.png",
                    title: "Franchise",
                    header: "",
                    descriptionPara: ["Scala Franchise allows you to run your multi-location business. Its consolidated and centralized data centers allows you to see the BIG picture. A 360-degree overview of sales and supply allows you to compare individual franchises, set specific management goals and monitor the results in real time."],
                    firstSection: [
                        "FULL FRANCHISE MANAGEMENT",
                        "UNLIMITED FRANCHISES",
                    ],
                    secondSection: [
                        "AUTOMATED FRANCHISE LEVEL PROCESSES",
                        "CENTRALIZED STOCK WITH AFFILIATE BRANCHES",
                    ],
                    thirdSection: [
                        "CORPORATE LEVEL INVENTORY VISIBILITY",
                        "REPORTING",
                    ]
                },
                {
                    id: "chinStoreERP-Payroll",
                    icon: "/images/chinStore/icons/ERP-Payroll.png",
                    title: "Payroll/HR",
                    header: "",
                    descriptionPara: ["The purpose behind the Payroll/HR application is to help you efficiently manage your most valuable asset – your labor force. Controlling costs, improving productivity, evaluating performance, all becomes an easy task that will in turn benefit your business.",
                    "Payroll/HR gives you access to all this information from a centralized source in order to generate necessary reports and take managerial decisions."],
                    firstSection: [
                        "FULL PAYROLL COMPUTATION",
                        "PAY SLIPS & PAYMENT SLIPS",
                        "AUTOMATED BANK PAYMENTS",
                        "BENEFITS & DEDUCTION MANAGEMENT",
                        "EMPLOYEE ID CARD PRINTING",
                        "COMPANY WORK RULES",
                    ],
                    secondSection: [
                        "ATTENDANCE MANAGEMENT",
                        "EMPLOYEES SCHEDULE MANAGEMENT",
                        "VACATION MANAGEMENT",
                        "OFFICIAL REPORTING R3, R4, R5, R6, R10",
                        "CNSS OFFICIAL REPORTS & WORKERS REGISTER",
                        "END OF SERVICE REPORTS",
                    ],
                    thirdSection: [
                        "HR WEB PORTAL",
                        "ONLINE EMPLOYMENT MANAGEMENT",
                        "APPRAISAL MANAGEMENT",
                        "TRAINING MODULE",
                        "PERSONNEL ALLOCATION",
                        "CERTIFICATES & ATTESTATIONS",
                    ]
                },
                {
                    id: "chinStoreERP-Financial",
                    icon: "/images/chinStore/icons/ERP-Financial.png",
                    title: "Financial",
                    header: "",
                    descriptionPara: ["The Financial Application offers a complete solution that will enable you to get rid of the manual processes and replace them with a reliable reporting and business analytical tool.",
                    "The different modules combined with the integrated information source will help you have a clear strategic vision while saving significant amount of labor inputs and unnecessary errors"],
                    firstSection: [
                        "GENERAL ACCOUNTING",
                        "MULTIPLE COMPANIES HANDLING",
                        "VAT MANAGEMENT",
                        "PAYMENTS & BANK RECONCILIATION",
                    ],
                    secondSection: [
                        "ACCOUNT RECEIVABLES/PAYABLES",
                        "CASH PROVISION",
                        "COST CENTER MANAGEMENT",
                        "CHECKS MODULE",
                    ],
                    thirdSection: [
                        "FIXED ASSETS",
                        "MAINTENANCE & SERVICE MODULE",
                        "PROFIT & LOSS STATEMENTS",
                        "BALANCE SHEET",
                    ]
                },
                {
                    id: "chinStoreERP-Intelligence",
                    icon: "/images/chinStore/icons/ERP-Intelligence.png",
                    title: "Intelligence",
                    header: "",
                    descriptionPara: ["Allow our Business Intelligence module to analyze your data and provide important information for you to take action. The solution gives you historical, current and predictive insight of your business operations to allow your executives and managers to see trends and make strategic business decisions. The Intelligence module goes beyond analyzing existing data.",
                    "It has the power to predict possible consumer behavior through data mining."],
                    firstSection: [
                        "BUSINESS INTELLIGENCE",
                        "DATA WAREHOUSE",
                    ],
                    secondSection: [
                        "CUBES MANAGEMENT",
                        "COST ACCOUNTING",
                    ],
                    thirdSection: [
                        "INFINITE REPORTS",
                        "FULLY INTEGRATED WITH REPORTING TOOLS",
                    ]
                },
                {
                    id: "chinStoreERP-Admin",
                    icon: "/images/chinStore/icons/ERP-Admin.png",
                    title: "Admin",
                    header: "",
                    descriptionPara: [],
                    firstSection: [
                        "FULL SECURITY & LOGGING",
                        "USERS & GROUP PRIVILEGES",
                        "AUTHORIZATION TO SPECIAL OPERATIONS",
                        "INTERNATIONAL SECURITY STANDARDS",
                        "FULL TRACKING OF USERS ACCESS",
                    ],
                    secondSection: [
                        "DATA INTEGRITY PRESERVATION",
                        "HIGH AVAILABILITY & CLUSTERED SOLUTION",
                        "FULL INTEGRATION OF MODULES",
                        "ONLINE HELP & TRAINING MANUAL",
                        "PERIODICAL UPDATE OF SYSTEM VERSIONS",
                    ],
                    thirdSection: [
                        "24/7 SUPPORT",
                        "PROACTIVE FRONT-LINE SUPPORT & FOLLOW UP",
                        "ONSITE & REMOTE CUSTOMER SUPPORT",
                        "COMPREHENSIVE INITIAL TRAINING",
                        "CONTINUOUS TRAINING ENVIRONMENT",
                    ]
                },]
            }

            break;

        case "PayrolHR":
            returnData =
            {
                imageSourse: "/images/payroll/PayrollBanner.jpg",
                data: [

                    {
                        id: "payrollpay-Payroll",
                        icon: "/images/payroll/icons/pay-Payroll.png",
                        title: "Payroll",
                        header: "",
                        descriptionPara: ["The purpose behind the Payroll/HR application is to help you efficiently manage your most valuable asset – your labor force. Controlling costs, improving productivity, evaluating performance, all becomes an easy task that will in turn benefit your business.",
                        "Payroll/HR gives you access to all this information from a centralized source in order to generate necessary reports and take managerial decisions. Our solution uses technology to automate what was in reality a complex burden on your business"],
                        firstSection: [
                            "FULL PAYROLL COMPUTATION",
                            "PAY SLIPS & PAYMENT SLIPS",
                            "AUTOMATED BANK PAYMENTS",
                        ],
                        secondSection: [
                            "BENEFITS & DEDUCTION MANAGEMENT",
                            "EMPLOYEE ID CARD PRINTING",
                            "COMPANY WORK RULES",
                        ],
                        thirdSection: [
                            "OFFICIAL REPORTING R3, R4, R5, R6, R10",
                            "CNSS OFFICIAL REPORTS & WORKERS REGISTER",
                            "END OF SERVICE REPORTS",
                        ]
                    },
                    {
                        id: "payrollpay-HR",
                        icon: "/images/payroll/icons/pay-HR.png",
                        title: "HR",
                        header: "",
                        descriptionPara: ["Our HR solution is designed to make staff management easier. People are complex. Likewise, our human resource software comprises countless features from recruiting, onboarding, performance, training, allocation and retention."],
                        firstSection: [
                            "HR WEB PORTAL",
                            "ONLINE EMPLOYMENT MANAGEMENT",
                            "APPRAISAL MANAGEMENT",
                        ],
                        secondSection: [
                            "TRAINING MODULE",
                            "PERSONNEL ALLOCATION",
                            "CERTIFICATES & ATTESTATIONS",
                        ],
                        thirdSection: [
                            "LOAN MANAGEMENT",
                            "ORIENTATION & INDUCTION",
                            "STAFF MANAGEMENT",
                        ]
                    },
                    {
                        id: "payrollpay-Attendance",
                        icon: "/images/payroll/icons/pay-Attendance.png",
                        title: "Attendance",
                        header: "",
                        descriptionPara: ["Replace the manual attendance spreadsheets and punching time cards with an automated solution that can keep track of your employee attendance, leaves, overtime and more. Minimize loss due to employee downtime by collecting attendance data automatically by using real-time systems."],
                        firstSection: [
                            "ATTENDANCE MANAGEMENT",
                            "HAND PUNCH INTEGRATION",
                        ],
                        secondSection: [
                            "OVERTIME MANAGEMENT",
                            "VACATION MANAGEMENT",
                        ],
                        thirdSection: [
                            "SCHEDULE MANAGEMENT",
                            "FULL INTEGRATION WITH PAYROLL",
                        ]
                    },
                    {
                        id: "payrollpay-Financial",
                        icon: "/images/payroll/icons/pay-Financial.png",
                        title: "Financial",
                        header: "",
                        descriptionPara: ["The Financial Application offers a complete solution that will enable you to get rid of the manual processes and replace them with a reliable reporting and business analytical tool.",
                        "The different modules combined with the integrated information source will help you have a clear strategic vision while saving significant amount of labor inputs and unnecessary errors"],
                        firstSection: [
                            "GENERAL ACCOUNTING",
                            "MULTIPLE COMPANIES HANDLING",
                            "VAT MANAGEMENT",
                            "PAYMENTS & BANK RECONCILIATION",
                        ],
                        secondSection: [
                            "ACCOUNT RECEIVABLES/PAYABLES",
                            "CASH PROVISION",
                            "COST CENTER MANAGEMENT",
                            "CHECKS MODULE",
                        ],
                        thirdSection: [
                            "FIXED ASSETS",
                            "MAINTENANCE & SERVICE MODULE",
                            "PROFIT & LOSS STATEMENTS",
                            "BALANCE SHEET",
                        ]
                    },
                    {
                        id: "payrollpay-Intelligence",
                        icon: "/images/payroll/icons/pay-Intelligence.png",
                        title: "Intelligence",
                        header: "",
                        descriptionPara: ["Allow our Business Intelligence module to analyze your data and provide important information for you to take action. The solution gives you historical, current and predictive insight of your business operations to allow your executives and managers to see trends and make strategic business decisions.",
                        "The Intelligence module goes beyond analyzing existing data. It has the power to predict possible consumer behavior through data mining."],
                        firstSection: [
                            "BUSINESS INTELLIGENCE",
                            "DATA WAREHOUSE",
                        ],
                        secondSection: [
                            "CUBES MANAGEMENT",
                            "COST ACCOUNTING",
                        ],
                        thirdSection: [
                            "INFINITE REPORTS",
                            "FULLY INTEGRATED WITH REPORTING TOOLS",
                        ]
                    },
                    {
                        id: "payrollpay-Admin",
                        icon: "/images/payroll/icons/pay-Admin.png",
                        title: "Admin",
                        header: "",
                        descriptionPara: [""],
                        firstSection: [
                            "FULL SECURITY & LOGGING",
                            "USERS & GROUP PRIVILEGES",
                            "AUTHORIZATION TO SPECIAL OPERATIONS",
                            "INTERNATIONAL SECURITY STANDARDS",
                            "FULL TRACKING OF USERS ACCESS",
                        ],
                        secondSection: [
                            "DATA INTEGRITY PRESERVATION",
                            "HIGH AVAILABILITY & CLUSTERED SOLUTION",
                            "FULL INTEGRATION OF MODULES",
                            "ONLINE HELP & TRAINING MANUAL",
                            "PERIODICAL UPDATE OF SYSTEM VERSIONS",
                        ],
                        thirdSection: [
                            "24/7 SUPPORT",
                            "PROACTIVE FRONT-LINE SUPPORT & FOLLOW UP",
                            "ONSITE & REMOTE CUSTOMER SUPPORT",
                            "COMPREHENSIVE INITIAL TRAINING",
                            "CONTINUOUS TRAINING ENVIRONMENT",
                        ]
                    },
                ]

            }
            break;

        case "NuTechHardware":
            returnData =
            {
                imageSourse: "/images/nutech/NutechBanner.jpg",
                data: [
                    {
                        id: "nutechnut-Servers",
                        icon: "/images/nutech/icons/nut-Servers.png",
                        title: "Intel Scalable Servers",
                        header: "",
                        descriptionPara: [],
                        firstSection: [
                            "CORPORATE SERVERS (RACKMOUNT & PEDESTAL)",
                            "ENTRY LEVEL SERVERS",
                            "INTEGRATED VIRTUALIZATION",
                        ],
                        secondSection: [],
                        thirdSection: []
                    },
                    {
                        id: "nutechnut-ESET",
                        icon: "/images/nutech/icons/nut-ESET.png",
                        title: "ESET NOD32",
                        header: "",
                        descriptionPara: [],
                        firstSection: [
                            "CORPORATE ANTIVIRUS",
                            "ANTI-SPAM",
                            "PERSONAL PROTECTION SUITE",
                        ],
                        secondSection: [],
                        thirdSection: []
                    },
                    {
                        id: "nutechnut-Cameras",
                        icon: "/images/nutech/icons/nut-Cameras.png",
                        title: "Security Cameras",
                        header: "",
                        descriptionPara: [],
                        firstSection: [
                            "HIGH RESOLUTION SECURITY CAMS",
                            "FACE RECOGNITION",
                            "PLATE RECOGNITION",
                        ],
                        secondSection: [],
                        thirdSection: []
                    },
                    {
                        id: "nutechnut-Barcode",
                        icon: "/images/nutech/icons/nut-Barcode.png",
                        title: "Barcodes & RFID",
                        header: "",
                        descriptionPara: [],
                        firstSection: [
                            "BARCODE PRINTERS (INDUSTRIAL & PERSONAL)",
                            "PORTABLE DATA COLLECTORS",
                            "RFID DATA COLLECTORS",
                        ],
                        secondSection: [],
                        thirdSection: []
                    },
                    {
                        id: "nutech",
                        icon: "/images/nutech/icons/nut-Peripherals.png",
                        title: "Peripherals",
                        header: "",
                        descriptionPara: [],
                        firstSection: [
                            "WIDE RANGE OF PRODUCTS FOR CORPORATE NEEDS",
                        ],
                        secondSection: [],
                        thirdSection: []
                    },
                ]
            }

            break;

        case "IntelXeon":
            returnData =
            {
                intelXeon: [
                    {
                        id: "platinum",
                        code: "platinum",
                        title: "INTEL® XEON® PLATINUM PROCESSORS",
                        goToDescription: "Reliability and serviceability for small business and storage server solutions.",
                        tableSectionDescription: "For secure, agile data centers, Intel® Xeon® Platinum Processors are the ideal solution. They are built for mission-critical, real-time analytics, machine learning, artificial intelligence, and cloud workloads thanks to their AI acceleration, advanced security technologies, and multi-socket processing performance.",
                        icon: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png",
                        image: "/images/intel/xeon-platinum-processors-framed-badge-rwd.jpg",
                        IntelXeonTable: [
                            { SKU: "8380", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "40", Threads: "80", TDP: "	270 W", BaseFreq: "2.30 GHz", Rpeak: "2944.00" },
                            { SKU: "8368Q", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "38", Threads: "76", TDP: "	270 W", BaseFreq: "2.60 GHz", Rpeak: "3161.60" },
                            { SKU: "8362", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "32", Threads: "64", TDP: "	265 W", BaseFreq: "2.80 GHz", Rpeak: "2867.20" },
                            { SKU: "8368", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "38", Threads: "76", TDP: "	270 W", BaseFreq: "2.40 GHz", Rpeak: "2918.40" },
                            { SKU: "8360Y", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "36", Threads: "72", TDP: "	250 W", BaseFreq: "2.40 GHz", Rpeak: "2764.80" },
                            { SKU: "8358P", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "32", Threads: "64", TDP: "	240 W", BaseFreq: "2.60 GHz", Rpeak: "2662.40" },
                            { SKU: "8358", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "32", Threads: "64", TDP: "	250 W", BaseFreq: "2.60 GHz", Rpeak: "2662.40" },
                            { SKU: "8352V", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "36", Threads: "72", TDP: "	195 W", BaseFreq: "2.10 GHz", Rpeak: "2419.20" },
                            { SKU: "8352Y", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "32", Threads: "64", TDP: "	205 W", BaseFreq: "2.20 GHz", Rpeak: "2252.80" },
                            { SKU: "8352", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "32", Threads: "64", TDP: "	205 W", BaseFreq: "2.20 GHz", Rpeak: "2252.80" },
                            { SKU: "8352M", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "32", Threads: "64", TDP: "	185 W", BaseFreq: "2.30 GHz", Rpeak: "2355.20" },
                            { SKU: "8351N", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-platinum.png", Level: "Xeon Platinum", Cores: "36", Threads: "72", TDP: "	225 W", BaseFreq: "2.40 GHz", Rpeak: "2764.80" },
                        ]
                    },
                    {
                        id: "gold",
                        code: "gold",
                        title: "INTEL® XEON® GOLD PROCESSORS",
                        goToDescription: "Performance and power efficiency for entry compute, network and storage.",
                        tableSectionDescription: "Intel® Xeon® Gold 6300 and 5300 processors deliver improved performance, enhanced memory functionality, advanced security technologies, and built-in workload acceleration, with support for higher memory speeds and larger memory capacities.",
                        icon: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png",
                        image: "/images/intel/xeon-gold-processors-framed-badge-rwd.jpg",
                        IntelXeonTable: [
                            { SKU: "6354", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "18", Threads: "36", TDP: "205 W", BaseFreq: "3.00 GHz", Rpeak: "1728.00" },
                            { SKU: "6348", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "28", Threads: "56", TDP: "235 W", BaseFreq: "2.60 GHz", Rpeak: "2329.60" },
                            { SKU: "6346", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "16", Threads: "32", TDP: "205 W", BaseFreq: "3.10 GHz", Rpeak: "1587.20" },
                            { SKU: "6342", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "230 W", BaseFreq: "2.80 GHz", Rpeak: "2150.40" },
                            { SKU: "6338T", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "165 W", BaseFreq: "2.10 GHz", Rpeak: "1612.80" },
                            { SKU: "6338", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "32", Threads: "64", TDP: "205 W", BaseFreq: "2.00 GHz", Rpeak: "2048.00" },
                            { SKU: "6338N", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "32", Threads: "64", TDP: "185 W", BaseFreq: "2.20 GHz", Rpeak: "2252.80" },
                            { SKU: "6336Y", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "185 W", BaseFreq: "2.40 GHz", Rpeak: "1843.20" },
                            { SKU: "6334", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "8", Threads: "16", TDP: "165 W", BaseFreq: "3.60 GHz", Rpeak: "921.60" },
                            { SKU: "6330", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "28", Threads: "56", TDP: "205 W", BaseFreq: "2.00 GHz", Rpeak: "1792.00" },
                            { SKU: "6330N", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "28", Threads: "56", TDP: "165 W", BaseFreq: "2.20 GHz", Rpeak: "1971.20" },
                            { SKU: "6326", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "16", Threads: "32", TDP: "185 W", BaseFreq: "2.90 GHz", Rpeak: "1484.80" },
                            { SKU: "6314U", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "32", Threads: "64", TDP: "205 W", BaseFreq: "2.30 GHz", Rpeak: "2355.20" },
                            { SKU: "6312U", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "185 W", BaseFreq: "2.40 GHz", Rpeak: "1843.20" },
                            { SKU: "5320T", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "20", Threads: "40", TDP: "150 W", BaseFreq: "2.30 GHz", Rpeak: "1472.00" },
                            { SKU: "5320", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "26", Threads: "52", TDP: "185 W", BaseFreq: "2.20 GHz", Rpeak: "1830.40" },
                            { SKU: "5318Y", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "165 W", BaseFreq: "2.10 GHz", Rpeak: "1612.80" },
                            { SKU: "5318S", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "165 W", BaseFreq: "2.10 GHz", Rpeak: "1612.80" },
                            { SKU: "5318N", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "24", Threads: "48", TDP: "150 W", BaseFreq: "2.10 GHz", Rpeak: "1612.80" },
                            { SKU: "5317", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "12", Threads: "24", TDP: "150 W", BaseFreq: "3.00 GHz", Rpeak: "1152.00" },
                            { SKU: "5315Y", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-gold.png", Level: "Xeon Gold", Cores: "8", Threads: "16", TDP: "140 W", BaseFreq: "3.20 GHz", Rpeak: "819.20" },
                        ]
                    },
                    {
                        id: "silver",
                        code: "silver",
                        title: "INTEL® XEON® SILVER PROCESSORS",
                        goToDescription: "Up to 4 socket scalable performance, advanced reliability, and advanced security solutions.",
                        tableSectionDescription: "The Intel® Xeon® Silver 4300 processor provides essential performance as well as improved memory speed, increased power efficiency, and integrated workload acceleration. Hardware-enhanced performance is required for entry-level data center computes, networks, and storage.",
                        icon: "/images/intel/ice-lake-intel-xeon-scalable-cpu-silver.png",
                        image: "/images/intel/xeon-silver-processors-framed-badge-rwd.jpg",
                        IntelXeonTable: [
                            { SKU: "4316", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-silver.png", Level: "Xeon Silver", Cores: "20", Threads: "40", TDP: "150 W", BaseFreq: "2.30 GHz", Rpeak: "1472.00" },
                            { SKU: "4314", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-silver.png", Level: "Xeon Silver", Cores: "16", Threads: "32", TDP: "135 W", BaseFreq: "2.40 GHz", Rpeak: "1228.80" },
                            { SKU: "4310T", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-silver.png", Level: "Xeon Silver", Cores: "10", Threads: "20", TDP: "105 W", BaseFreq: "2.30 GHz", Rpeak: "736.00" },
                            { SKU: "4310", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-silver.png", Level: "Xeon Silver", Cores: "12", Threads: "24", TDP: "120 W", BaseFreq: "2.10 GHz", Rpeak: "806.40" },
                            { SKU: "4309Y", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-silver.png", Level: "Xeon Silver", Cores: "8", Threads: "16", TDP: "105 W", BaseFreq: "2.80 GHz", Rpeak: "716.80" },
                        ]
                    },
                    {
                        id: "bronze",
                        code: "bronze",
                        title: "INTEL® XEON® BRONZE PROCESSORS",
                        goToDescription: "Advanced 2, 4 & 8 socket performance, designed for the most demanding workloads & services from the edge to cloud.",
                        tableSectionDescription: "Small businesses and basic storage servers can benefit from Intel® Xeon® Bronze processors. A combination of hardware and software designed to make these entry-level solutions more reliable, available, and serviceable.",
                        icon: "/images/intel/ice-lake-intel-xeon-scalable-cpu-bronze.png",
                        image: "/images/intel/xeon-bronze-processors-framed-badge-rwd.jpg",
                        IntelXeonTable: [
                            { SKU: "3206R", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-bronze.png", Level: "Xeon Bronze", Cores: "8", Threads: "8", TDP: "85 W", BaseFreq: "1.90 GHz", Rpeak: "486.40" },
                            { SKU: "3204", IconLevel: "/images/intel/ice-lake-intel-xeon-scalable-cpu-bronze.png", Level: "Xeon Bronze", Cores: "6", Threads: "6", TDP: "85 W", BaseFreq: "1.90 GHz", Rpeak: "364.80" },
                        ]
                    },
                ],
            }

            break;

        case "Activities":
            returnData =
                [
                    {
                        ActivitieCode: "harisa23",
                        ActivitieTitle: "Pilgrimage to Harissa 01/05/2023 ",
                        ActivitieDescription: "",
                        ActivitieImgs: [
                            "/images/aboutUs/23harisa/23harisa1.jpg",
                            "/images/aboutUs/23harisa/23harisa2.jpg",
                            "/images/aboutUs/23harisa/23harisa3.jpg",
                            "/images/aboutUs/23harisa/23harisa4.jpg",
                            "/images/aboutUs/23harisa/23harisa5.jpg"
                        ],
                    },
                ]

            break;


        default:
            returnData = {}
            break;
    }
    return returnData
};

// export function getCtservNetMainImg(val) {
//     switch (val) {
//         case "hospitals": return ("/images/hospitals/MileraHospitalsBanner.jpg")
//             break;
//         case "medicalCenters": return "/images/medicalCenters/MileraMedicalCentersBanner.jpg"
//             break;
//         case "supermarket": return "/images/supermarkets/MangoBanner.jpg"
//             break;
//         case "chainStores": return "/images/chinStore/ScalaBanner2.jpg"
//             break;
//         case "payroll": return "/images/payroll/PayrollBanner.jpg"
//             break;
//         case "nuTech": return "/images/nutech/NutechBanner.jpg"
//             break;
//         default:
//     }
// }





















