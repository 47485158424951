import React, { Component } from 'react';
import { map, cloneDeep } from "lodash"
import "./bigSpyNav.css"

class BigSpyNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        document.getElementById("companyProductsContainer").addEventListener("scroll", this.onScrollGoToSection);
        this.setState({ data: this.props.data })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(nextProps) !== JSON.stringify(this.state))
    }

    componentDidUpdate() {
        if (this.props.data[0]?.id !== this.state.data[0]?.id)
            this.setState({ data: this.props.data })
    }

    componentWillUnmount() {
        document.getElementById("companyProductsContainer").removeEventListener("scroll", this.onScrollGoToSection);
    }

    onScrollGoToSection = () => {
        let data = cloneDeep(this.state.data)
        let screen = document.getElementById("companyProductsContainer")

        if (screen.scrollHeight - 100 <= screen.scrollTop + document.getElementById("companyProductsImg").offsetHeight) {
            map(data, elem => {
                elem.colored = false
            })
            data[data.length - 1].colored = true;

        } else {
            map(data, elem => {
                let placeOnTheScreen = screen.scrollTop
                let elemScreen = document.getElementById(elem.id)?.offsetTop +document.getElementById("companyProductsImg").offsetHeight
                if (placeOnTheScreen + 100 >= elemScreen && placeOnTheScreen <= elemScreen + document.getElementById(elem.id).offsetHeight - 80)
                    elem.colored = true
                else
                    elem.colored = false
            })

        }


        this.setState({ data });
    }

    scrolltolocation = (val, iconId) => event => {
        let data = cloneDeep(this.state.data)
        let tempHeight = document.getElementById(val).offsetTop+document.getElementById("companyProductsImg").offsetHeight
        document.getElementById("companyProductsContainer").scroll({
            top: tempHeight - 76,
            behavior: 'smooth'
        });

        map(data, elem => {
            if (elem.iconId === iconId)
                elem.colored = true
            else
                elem.colored = false
        })
        this.setState({ data });
    }

    render() {
        return (<>
            < div id="bigScrollSpyNav">
                <div className="scrollSpayNavList">
                    {this.state.data &&
                        map(this.state.data, (elem, key) => {
                            return <div
                                key={"navIcons" + key}>
                                <span onClick={this.scrolltolocation(elem.id, elem.iconId)}>
                                    <img
                                        className="navIcons"
                                        src={elem.icon && elem.icon}
                                        alt=""
                                        style={!elem.colored ? { filter: "grayscale(100%)" } : {}} />
                                </span>
                            </div>
                        })
                    }
                </div>
            </div >
        </>
        );
    }
}

export default BigSpyNav;